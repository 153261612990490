import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import Notification from '../../models/notification';
import Performance from '../../models/performance';

export const types = {
  RESET_DATA: 'COMMON/RESET_DATA',
  NOTIFICATION_REQUEST: 'CART/NOTIFICATION_REQUEST',
  NOTIFICATION_SUCCESS: 'CART/NOTIFICATION_SUCCESS',
  NOTIFICATION_ERROR: 'CART/NOTIFICATION_ERROR',

  NOTIFICATION_READ_REQUEST: 'CART/NOTIFICATION_READ_REQUEST',
  NOTIFICATION_READ_SUCCESS: 'CART/NOTIFICATION_READ_SUCCESS',
  NOTIFICATION_READ_ERROR: 'CART/NOTIFICATION_READ_ERROR',
};

export interface NotificationState {
  notifications?: Notification[];
  isNotificationLoading: boolean;
  isNotificationLoaded: boolean;
  totalCount?: number;
  perPageCount?: number;
  unreadCount?: number;
}

const initialState: NotificationState = {
  isNotificationLoading: false,
  isNotificationLoaded: false,
};

export default (
  state: NotificationState = initialState,
  action: any
): NotificationState => {
  switch (action.type) {
    case types.RESET_DATA:
      return {
        ...initialState,
      };
    case types.NOTIFICATION_REQUEST:
      return {
        ...state,
        isNotificationLoading: true,
        isNotificationLoaded: false,
      };
    case types.NOTIFICATION_SUCCESS:
      const notificationList =
        Number(action.page) <= 1
          ? [...(action.data.result || [])]
          : [
              ...(state.notifications ? state.notifications : []),
              ...action.data.result,
            ];
      return {
        ...state,
        notifications: notificationList,
        isNotificationLoading: false,
        isNotificationLoaded: true,
        totalCount: action.data ? action.data.totalCount : 1,
        unreadCount: action.data ? action.data.unreadCount : 1,
        perPageCount: 10,
      };
    case types.NOTIFICATION_ERROR:
      return {
        ...state,
        isNotificationLoading: false,
        isNotificationLoaded: true,
        totalCount: 0,
        unreadCount: 0,
      };
    case types.NOTIFICATION_READ_REQUEST:
      return {
        ...state,
      };
    case types.NOTIFICATION_READ_SUCCESS:
      return {
        ...state,
        unreadCount: 0,
      };
    case types.NOTIFICATION_READ_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const actions = {
  clearData: (onComplete?: () => void) => async (dispatch: Dispatch) => {
    await dispatch({ type: types.RESET_DATA });
    if (onComplete) {
      onComplete();
    }
  },
  getNotification: (page: number, userType?: string) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    api.setWebsiteId(state.authUser.websiteId);
    api.setSubsiteId(state.authUser.subsiteId);
    dispatch({ type: types.NOTIFICATION_REQUEST });
    try {
      const response = await api.notification.getNotification(page, userType);
      const { data } = response;
      dispatch({ type: types.NOTIFICATION_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.NOTIFICATION_ERROR });
      throw error;
    }
  },
  changeReadStatus: () => async (dispatch: Dispatch) => {
    dispatch({ type: types.NOTIFICATION_READ_REQUEST });
    try {
      const response = await api.notification.changeReadStatus();
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
      } else {
        dispatch({
          type: types.NOTIFICATION_READ_SUCCESS,
          data,
        });
      }
    } catch (error) {
      dispatch({
        type: types.NOTIFICATION_READ_ERROR,
        data: {},
      });
    }
  },
};
