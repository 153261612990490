import * as http from './http';
import ApiService from './service';

export default class UserService extends ApiService {
  public async getBanners() {
    const url = `${this.apiDomain}/product/banners/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/userToken/${this.token ? this.token : ''}/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'
      }/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/platform/Web/`;

    const response = await http.get(url);
    return { data: response.data };
  }
  public async getQuarterlyPerformance() {
    const url = `${this.apiDomain}/user/quarterlyperformance/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/userToken/${this.token ? this.token : ''}/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'
      }/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/platform/Web/`;

    const response = await http.get(url);
    return { data: response.data };
  }
  public async quarterlyPerformanceJoin(userTargetID: number) {
    const url = `${this.apiDomain}/user/quarterlyperformancejoin`;
    const data = new FormData();

    data.append('userTargetID', userTargetID ? userTargetID.toString() : '');
    data.append('userToken', this.token ? this.token : '');

    data.append('website_id', this.websiteId ? this.websiteId.toString() : '1');
    data.append('subsite_id', this.subsiteId ? this.subsiteId.toString() : '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }
  public async getFAQ() {
    const url = `${this.apiDomain}/faq/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/userToken/${this.token ? this.token : ''}/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'
      }/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/platform/Web/`;

    const response = await http.get(url);
    return { data: response.data };
  }

  public async getTerms() {
    const token = this.token ? this.token : '';
    const url = `${this.apiDomain}/user/gettermsofuse/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/1/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/userToken/${token}`;
    return http.get(url, token);
  }

  public async getCategory() {
    const url = `${this.apiDomain}/product/categories/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/userToken/${this.token ? this.token : ''}/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'
      }/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/platform/Web/`;

    const response = await http.get(url);
    return { data: response.data };
  }
  public async getLanguages() {
    const url = `${this.apiDomain}/settings/languages/`;
    const response = await http.get(url);
    return { data: response.data };
  }

  public async getSurvey(type:string, trigger?:string) {
    const url = `${this.apiDomain}/survey/surveyList/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'
      }/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/userToken/${this.token ? this.token : ''}/${type==='List'? '':'triggerPoint/'}${trigger? trigger : 'Application'}/display/${type}/`;
    const response = await http.get(url);
    return { data: response.data };
  }
  public async startSurvey(questionData: any) {
    const masterOrderID = `/masterOrderID/${questionData?.masterOrderID}`;
    const url = `${this.apiDomain}/survey/questionList/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'
      }/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/userToken/${this.token ? this.token : ''}/surveyID/${questionData.surveyID}${questionData?.masterOrderID?masterOrderID:""}`;
    const response = await http.get(url);
    return { data: response.data };
  }
  public async saveQuestion(questionData: any, userToken:string) {
    console.log(questionData)
    let url = `${this.apiDomain}/survey/saveResponse`;
    const response = await http.postWithUserToken(url, JSON.stringify(questionData), userToken);
    return { data: response.data };
  }
  public async saveFile(data: any, userToken:string, userType:string) {
    console.log(data)
    let url:string;
    if(userType === "Customer"){
      url = `${this.apiDomain}/usertarget/feedbackfile/`;
    }
    else{
      url = `${this.apiDomain}/Salesperson/feedbackfile/`;
    }
    const response = await http.postAsFormData(url, data, userToken);
    return { data: response.data };
  }
  public async saveFeedBack(data: any, userToken:string, userType:string) {
    console.log(data)
    let url:string;
    if(userType === "Customer"){
      url = `${this.apiDomain}/usertarget/saveFeedback/`;
    }
    else{
      url = `${this.apiDomain}/Salesperson/saveFeedback/`;
    }
    const response = await http.postAsFormData(url, data, userToken);
    return { data: response.data };
  }
  public async getfeedabck(id:string, userToken:string, userType:string) {
    let url:string;
    if(userType === "Customer"){
      url = `${this.apiDomain}/usertarget/getFeedbackDetails/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'
      }/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/userToken/${this.token ? this.token : ''}/feedbackID/${id ? id : ''}/`;
    }
    else{
      url = `${this.apiDomain}/salesperson/getFeedbackDetails/website_id/${this.websiteId ? this.websiteId.toString() : '1'
      }/subsite_id/${this.subsiteId ? this.subsiteId.toString() : '1'
      }/languageID/${this.languageId ? this.languageId.toString() : '1'
      }/salespersonToken/${this.token ? this.token : ''}/feedbackID/${id ? id : ''}/`;
    }
    const response = await http.get(url);
    return { data: response.data };
  }


}
