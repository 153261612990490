import * as http from './http';
import ApiService from './service';

export default class NotificationService extends ApiService {
  public async getNotification(page: number, userType?: string) {
    const tokenType =
      userType === 'Salesperson' ? 'salespersonToken' : 'userToken';

    const url = `${this.apiDomain}/notification/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/${tokenType}/${this.token ? this.token : ''}/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/page/${page}`;

    const response = await http.get(url);
    return { data: response.data };
  }

  public async changeReadStatus() {
    const url = `${this.apiDomain}/notification/read/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/userToken/${this.token ? this.token : ''}/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${this.languageId ? this.languageId.toString() : '1'}`;

    const data = new FormData();

    return http.postAsFormData(url, data);
  }
}
