import { stringify } from 'query-string';
import * as http from './http';
import ApiService from './service';
import { Customer } from '../../models/customer';
import { CrudListRequestModel } from '../models';
import { LeaveRequestFormModel } from '../../models';

const serviceEndpoint = 'salesperson';

export default class SalesPersonService extends ApiService {
  public async getCustomerList(
    page?: number,
    keyWord?: string,
    status?: string
  ) {
    let url = `${this.apiDomain}/${serviceEndpoint}/customers/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}`;
    if (keyWord) {
      url = url + `/customer/${keyWord}`;
    }
    if (status) {
      url = url + `/status/${status}`;
    }
    // for (const key of Object.keys(filters)) {
    //   if (filters[key] && filters[key] !== '0') {
    //     data.append(key, filters[key]);
    //   }
    // }

    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async customerCreate(userData: Customer) {
    const url = `${this.apiDomain}/${serviceEndpoint}/createcustomer`;
    const data = new FormData();
    data.append(
      'userFirstName',
      userData.userFirstName ? userData.userFirstName : ''
    );
    data.append(
      'userLastName',
      userData.userLastName ? userData.userLastName : ''
    );
    data.append('userPhone', userData.userPhone ? userData.userPhone : '');
    data.append(
      'userPhoneCountryCode',
      userData.userPhoneCountryCode ? userData.userPhoneCountryCode : ''
    );
    data.append(
      'userPhoneDialCode',
      userData.userPhoneDialCode ? userData.userPhoneDialCode : ''
    );

    data.append(
      'userDesignation',
      userData.userDesignation ? userData.userDesignation : ''
    );
    if (userData.userEmail) {
      data.append('userEmail', userData.userEmail ? userData.userEmail : '');
    }
    data.append(
      'accountCode',
      userData.accountCode ? userData.accountCode : ''
    );
    data.append(
      'billingName',
      userData.billingName ? userData.billingName : ''
    );
    data.append('tradeno', userData.tradeno ? userData.tradeno : '');
    data.append('trn', userData.trn ? userData.trn : '');
    data.append('salespersonToken', this.token ? this.token : '');
    data.append('website_id', this.websiteId ? this.websiteId.toString() : '1');
    data.append('subsite_id', this.subsiteId ? this.subsiteId.toString() : '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    data.append('action', 'update');
    return http.postAsFormData(url, data);
  }

  public async customerCartList(page?: number, keyWord?: string) {
    let url = `${this.apiDomain}/${serviceEndpoint}/customercart/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}`;
    if (keyWord) {
      url = url + `/customer/${keyWord}`;
    }
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async CustomerOrderList(page?: number, filters?: any) {
    let url = `${this.apiDomain}/${serviceEndpoint}/ordercustomers/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}`;
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          url = url + `/${key}/${filters[key]}`;
        }
      }
    }
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async LeaveRequestList(page?: number, filters?: any) {
    let url = `${
      this.apiDomain
    }/${serviceEndpoint}/leaveRequests/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}/`;
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          url = url + `${key}/${filters[key]}/`;
        }
      }
    }
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async OrderList(page?: number, filters?: any) {
    let url = `${this.apiDomain}/${serviceEndpoint}/orderlist/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}`;
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          url = url + `/${key}/${filters[key]}`;
        }
      }
    }
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async OrderDetails(filters?: any) {
    let url = `${this.apiDomain}/${serviceEndpoint}/orderlist/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}`;
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          url = url + `/${key}/${filters[key]}`;
        }
      }
    }
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async CustomerHighOrderList(page?: number, keyWord?: string) {
    let url = `${this.apiDomain}/${serviceEndpoint}/highorders/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}`;
    if (keyWord) {
      url = url + `/customer/${keyWord}`;
    }
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async approveOrder(orderID: number, status: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/highorderstatus`;
    const data = new FormData();

    data.append('orderID', orderID ? orderID.toString() : '');
    data.append('status', status);
    data.append('salespersonToken', this.token ? this.token : '');

    data.append('website_id', this.websiteId ? this.websiteId.toString() : '1');
    data.append('subsite_id', this.subsiteId ? this.subsiteId.toString() : '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }

  public async getProfile() {
    const url = `${this.apiDomain}/${serviceEndpoint}/profile/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}`;

    return http.get(url, this.token);
  }

  public async getPerformanceChart(
    type?: string,
    fromDate?: string,
    toDate?: string
  ) {
    let url = `${this.apiDomain}/${serviceEndpoint}/performance/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}`;
    if (type) {
      url = url + `/type/${type}`;
    }
    if (fromDate) {
      url = url + `/fromMonth/${fromDate}`;
    }
    if (toDate) {
      url = url + `/toMonth/${toDate}`;
    }
    return http.get(url, this.token);
  }

  public async changeOrderStatus(
    orderID: number,
    status: string,
    reason?: string,
    location?: string,
    latitude?: string,
    longitude?: string,
    deviceID?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/orderstatus`;
    const data = new FormData();

    data.append('orderID', orderID ? orderID.toString() : '');
    data.append('status', status);
    data.append('reason', reason ? reason : '');
    data.append('salespersonToken', this.token ? this.token : '');

    data.append('website_id', this.websiteId ? this.websiteId.toString() : '1');
    data.append('subsite_id', this.subsiteId ? this.subsiteId.toString() : '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    data.append('deviceID', deviceID!);
    data.append('location', location!);
    data.append('latitude', latitude!);
    data.append('longitude', longitude!);
    data.append('source', 'Website');
    return http.postAsFormData(url, data);
  }

  public async changeBulkOrderStatus(
    orderID: string[],  
    status: string,
    reason?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/bulkOrderStatus/`;
    // const url = `https://ajithmr.oorjit.net/hommer-b2b/mobileapp/salesperson/bulkOrderStatus`
    const data = new FormData();

    // data.append('orderID', orderID ? orderID.toString() : '');
    data.append('status', status);
    data.append('reason', reason ? reason : '');
    data.append('salespersonToken', this.token ? this.token : '');

    data.append('website_id', '2');
    data.append('subsite_id', '2');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    orderID?.forEach((item,index) => {
      data.append(`orders[${index}][orderID]`, item)
   })

    return http.postAsFormData(url, data);
  }

  public async updateOrder(
    orderID: number,
    products: {
      quantity: number;
      productOptionID: number;
    }[]
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/orderlist/website_id/${
      this.websiteId ? this.websiteId.toString() : '1'
    }/subsite_id/${
      this.subsiteId ? this.subsiteId.toString() : '1'
    }/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/orderEdit/Yes/`;

    const data = new FormData();

    data.append('orderID', orderID ? orderID.toString() : '');
    products.forEach((prd) => {
      data.append(
        `products[${prd.productOptionID}]`,
        prd.quantity ? prd.quantity.toString() : '0'
      );
    });

    return http.postAsFormData(url, data);
  }
  public async getLeaveTypes() {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/leaveType/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}`;

    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async addLeaveRequest(values: LeaveRequestFormModel) {
    const url = `${this.apiDomain}/${serviceEndpoint}/createLeaveRequest`;
    const data = new FormData();

    data.append('leaveRequestStartDate', values?.leaveRequestStartDate);
    data.append('leaveRequestEndDate', values?.leaveRequestEndDate);
    data.append('leaveTypeID', values?.leaveTypeID);
    data.append('leaveRequestReason', values?.leaveRequestReason);

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    data.append('salespersonToken', this.token ? this.token : '');

    return http.postAsFormData(url, data);
  }

  public async getLeaveRequestdetails(leaveRequestID: string) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/leaveRequestDetails/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/leaveRequestID/${leaveRequestID}/`;

    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async cancelLeaveRequest(values: any) {
    const url = `${this.apiDomain}/${serviceEndpoint}/cancelLeaveRequest`;
    const data = new FormData();

    data.append('leaveRequestID', values?.leaveRequestID);
    data.append('leaveRequestCancelReason', values?.leaveRequestCancelReason);

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    data.append('salespersonToken', this.token ? this.token : '');

    return http.postAsFormData(url, data);
  }
}
