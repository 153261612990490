// import * as UserApi from '../api/user';

import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';
import User from '../../models/user';
import { CustomerCart } from '../../models/customer';
import api from '../../api';
import { types as commonTypes } from './common';

// action types
export const types = {
  // login
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_ERROR: 'AUTH/LOGIN_ERROR',
  REDIRECT_OTP: 'AUTH/REDIRECT_OTP',

  // password
  PASSWORD_REQUEST: 'AUTH/PASSWORD_REQUEST',
  PASSWORD_SUCCESS: 'AUTH/PASSWORD_SUCCESS',
  PASSWORD_ERROR: 'AUTH/PASSWORD_ERROR',

  // otp
  OTP_REQUEST: 'AUTH/OTP_REQUEST',
  OTP_SUCCESS: 'AUTH/OTP_SUCCESS',
  TERMS_SUCCESS: 'AUTH/TERMS_SUCCESS',
  OTP_ERROR: 'AUTH/OTP_ERROR',

  // otp
  RESEND_OTP_REQUEST: 'AUTH/RESEND_OTP_REQUEST',
  RESEND_OTP_SUCCESS: 'AUTH/RESEND_OTP_SUCCESS',
  RESEND_OTP_ERROR: 'AUTH/RESEND_OTP_ERROR',

  // logout
  LOGOUT: 'AUTH/LOGOUT',
  // redirect
  REDIRECT_SAVE: 'AUTH/REDIRECT_SAVE',
  REDIRECT_APPLY: 'AUTH/REDIRECT_APPLY',

  // forgot password
  FORGOT_PASSWORD_REQUEST: 'AUTH/FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'AUTH/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'AUTH/FORGOT_PASSWORD_ERROR',

  // change password
  CHANGE_PASSWORD_REQUEST: 'AUTH/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'AUTH/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'AUTH/CHANGE_PASSWORD_ERROR',

  // User profile
  FETCH_PROFILE_REQUEST: 'USER/FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS: 'USER/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_ERROR: 'USER/FETCH_PROFILE_ERROR',

  CHANGE_USER: 'USER/CHANGE_USER',
  SWiICH_USER: 'USER/SWiICH_USER',
  CHANGE_LANGUAGE: 'USER/CHANGE_LANGUAGE',

  SET_CURRENCY: 'USER/SET_CURRENCY',
  SET_WEBSITE: 'USER/SET_WEBSITE',

  SET_TAXCLASS: 'USER/SET_TAXCLASS',
};

export interface AuthState {
  isLoading: boolean;
  authToken?: string;
  redirectTo: string;
  countryCode?: string;
  dialCode?: string;
  phoneNumber?: string;
  loginError?: string;
  otpError?: string;
  verified?: boolean;
  forgotPasswordError?: string;
  changePasswordError?: string;
  userLogin?: string;
  userToken?: string;
  privileges?: any;
  userType?: string;
  acceptTerms?: string;
  user?: User;
  isUserLoaded: boolean;
  isUserLoading: boolean;
  customerToken?: boolean;
  customerSelected?: any;
  languageID?: number;
  languageKey?: string;
  languageName?: string;
  languageDirection?: string;
  loginOTP?: string;
  currency?: string;
  phoneDialCode?: string;
  countryShortCode?: string;
  websiteId?: number;
  subsiteId?: number;
  taxClass?: string;
}

interface ForgotPasswordState {
  id?: string;
  error?: string;
}

// initial state
const initialState: AuthState = {
  isLoading: false,
  redirectTo: '/',
  isUserLoading: false,
  isUserLoaded: false,
  languageID: 3,
  languageKey: 'ar',
  languageName: 'العربية',
  languageDirection: 'rtl',
  currency: 'LYD',
  phoneDialCode: '218',
  countryShortCode: 'LY',
  websiteId: 1,
  subsiteId: 1,
};

// reducer
export default (state: AuthState = initialState, action: any): AuthState => {
  switch (action.type) {
    // login
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        loginError: undefined,
        otpError: undefined,
      };
    case types.LOGIN_SUCCESS:
      localStorage.setItem(
        'homer-token',
        action.data.verified && action.data.acceptTerms === 'Yes'
          ? action.data.userToken
          : undefined
      );
      return {
        ...state,
        isLoading: false,
        authToken:
          action.data.verified && action.data.acceptTerms === 'Yes'
            ? action.data.userToken
            : undefined,
        acceptTerms: action.data.acceptTerms,
        privileges: action.data ? action.data.privileges : undefined,
        userType: action.data ? action.data.userType : undefined,
        verified: action.data.verified,
        loginError: undefined,
        otpError: undefined,
        languageID:
          action.data &&
          action.data.userDefaultLanguage &&
          action.data.verified &&
          action.data.acceptTerms === 'Yes'
            ? action.data.userDefaultLanguage?.languageID
            : 3,
        languageKey:
          action.data &&
          action.data.userDefaultLanguage &&
          action.data.verified &&
          action.data.acceptTerms === 'Yes'
            ? action.data.userDefaultLanguage?.languageCode
            : 'ar',
        languageName:
          action.data &&
          action.data.userDefaultLanguage &&
          action.data.verified &&
          action.data.acceptTerms === 'Yes'
            ? action.data.userDefaultLanguage?.languageName
            : 'العربية',
        languageDirection:
          action.data &&
          action.data.userDefaultLanguage &&
          action.data.verified &&
          action.data.acceptTerms === 'Yes'
            ? action.data.userDefaultLanguage?.languageDirection
            : 'rtl',
        websiteId: action.data.websiteId,
        subsiteId: action.data.subsiteId,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,
        loginError: action.data ? action.data.errorMessage : undefined,
        otpError: action.data ? action.data.otpErrorMessage : undefined,
        // languageID: 3,
        // languageKey: 'ar',
        // languageName: 'العربية',
        // languageDirection: 'rtl',
      };
    case types.REDIRECT_OTP:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,
        loginError: undefined,
        loginOTP: action.data?.loginOTP,
      };
    case types.PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        loginError: undefined,
      };
    case types.PASSWORD_SUCCESS:
      localStorage.setItem('homer-token', action.data.userToken);
      return {
        ...state,
        isLoading: false,
        authToken: action.data.userToken,
        loginError: undefined,
        languageID:
          action.data && action.data.userDefaultLanguage
            ? action.data.userDefaultLanguage?.languageID
            : 3,
        languageKey:
          action.data && action.data.userDefaultLanguage
            ? action.data.userDefaultLanguage?.languageCode
            : 'ar',
        languageName:
          action.data && action.data.userDefaultLanguage
            ? action.data.userDefaultLanguage?.languageName
            : 'العربية',
        languageDirection:
          action.data && action.data.userDefaultLanguage
            ? action.data.userDefaultLanguage?.languageDirection
            : 'rtl',
      };
    case types.PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,
        loginError: action.data ? action.data.errorMessage : undefined,
        // languageID: 3,
        // languageKey: 'ar',
        // languageName: 'العربية',
        // languageDirection: 'rtl',
      };
    // login
    case types.OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        loginError: undefined,
        otpError: undefined,
      };
    case types.OTP_SUCCESS:
      localStorage.setItem(
        'homer-token',
        action.passwordExists ? action.data : undefined
      );
      return {
        ...state,
        isLoading: false,
        authToken: action.passwordExists ? action.data : undefined,
        verified: true,
        loginError: undefined,
        otpError: undefined,

        languageID:
          action.passwordExists &&
          action.userDefaultLanguage &&
          action.passwordExists
            ? action.userDefaultLanguage?.languageID
            : 3,
        languageKey:
          action.passwordExists &&
          action.userDefaultLanguage &&
          action.passwordExists
            ? action.userDefaultLanguage?.languageCode
            : 'ar',
        languageName:
          action.passwordExists &&
          action.userDefaultLanguage &&
          action.passwordExists
            ? action.userDefaultLanguage?.languageName
            : 'العربية',
        languageDirection:
          action.passwordExists &&
          action.userDefaultLanguage &&
          action.passwordExists
            ? action.userDefaultLanguage?.languageDirection
            : 'rtl',
      };
    case types.OTP_ERROR:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,
        loginError: action.data ? action.data.errorMessage : undefined,
        otpError: action.data ? action.data.otpErrorMessage : undefined,

        // languageID: 3,
        // languageKey: 'ar',
        // languageName: 'العربية',
        // languageDirection: 'rtl',
      };
    case types.TERMS_SUCCESS:
      localStorage.setItem(
        'homer-token',
        action.data ? action.data.userToken : undefined
      );
      return {
        ...state,
        isLoading: false,
        authToken: action.data ? action.data.userToken : undefined,
        privileges: action.data ? action.data.privileges : undefined,
        userType: action.data ? action.data.userType : undefined,
        verified: true,
        loginError: undefined,
        otpError: undefined,

        languageID:
          action.data && action.data.userDefaultLanguage
            ? action.data.userDefaultLanguage?.languageID
            : 3,
        languageKey:
          action.data && action.data.userDefaultLanguage
            ? action.data.userDefaultLanguage?.languageCode
            : 'ar',
        languageName:
          action.data && action.data.userDefaultLanguage
            ? action.data.userDefaultLanguage?.languageName
            : 'العربية',
        languageDirection:
          action.data && action.data.userDefaultLanguage
            ? action.data.userDefaultLanguage?.languageDirection
            : 'rtl',
      };
    // logout
    case types.LOGOUT:
      api.setToken(undefined);
      api.setCustomerToken(undefined);
      api.setCustomerLanguage(3);
      api.setCustomerLanguageKey('ar');

      api.setWebsiteId(1);
      api.setSubsiteId(1);

      localStorage.removeItem('authUser');
      localStorage.removeItem('cart');
      localStorage.removeItem('homer-token');
      localStorage.clear();
      return {
        ...state,
        authToken: undefined,
        privileges: undefined,
        userToken: undefined,
        userLogin: undefined,
        userType: undefined,
        customerToken: undefined,
        customerSelected: undefined,
        languageID: 3,
        languageKey: 'ar',
        languageName: 'العربية',
        languageDirection: 'rtl',
        currency: 'LYD',
        phoneDialCode: '218',
        countryShortCode: 'LY',
        websiteId: 1,
        subsiteId: 1,
      };
    case commonTypes.RESET_DATA:
      return {
        ...initialState,
        countryCode: state.countryCode,
        dialCode: state.dialCode,
        phoneNumber: state.phoneNumber,
      };

    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        changePasswordError: undefined,
        forgotPasswordError: undefined,
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userLogin: undefined,
        userToken: undefined,

        changePasswordError: undefined,
        languageID: 3,
        languageKey: 'ar',
        languageName: 'العربية',
        languageDirection: 'rtl',
      };
    case types.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,

        languageID: 3,
        languageKey: 'ar',
        languageName: 'العربية',
        languageDirection: 'rtl',
        changePasswordError: action.data ? action.data.errorMessage : undefined,
      };

    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        forgotPasswordError: undefined,
        changePasswordError: undefined,
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userLogin: action.userLogin,
        userToken: action.data.userToken,
        forgotPasswordError: undefined,
      };
    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,

        languageID: 3,
        languageKey: 'ar',
        languageName: 'العربية',
        languageDirection: 'rtl',
        forgotPasswordError: action.data ? action.data.errorMessage : undefined,
      };

    case types.RESEND_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        forgotPasswordError: undefined,
        changePasswordError: undefined,
      };
    case types.RESEND_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.RESEND_OTP_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    // profile
    case types.FETCH_PROFILE_REQUEST:
      return { ...state, isUserLoading: true };
    case types.FETCH_PROFILE_SUCCESS:
      const obj = {
        ...state,
        isUserLoading: false,
        user: action.data ? action.data.result : null,
        isUserLoaded: !!action.data,
      };
      if (!obj.user) {
        obj.authToken = undefined;
        obj.privileges = undefined;
        obj.userToken = undefined;
        obj.userLogin = undefined;
        obj.userType = undefined;
      }
      return obj;
    case types.FETCH_PROFILE_ERROR:
      return { ...state, isUserLoading: false };

    // redirect
    case types.REDIRECT_SAVE:
      return { ...state, redirectTo: action.data };
    case types.REDIRECT_APPLY:
      return { ...state, redirectTo: '/' };

    case types.CHANGE_USER:
      return {
        ...state,
        customerToken: action.data ? action.data.customerToken : undefined,
        customerSelected: action.data,
      };
    case types.SWiICH_USER:
      return {
        ...state,
        customerToken: action.data ? action.data.customerToken : undefined,
        customerSelected: action.data,
      };
    case types.CHANGE_LANGUAGE:
      return {
        ...state,
        languageID: action.data ? action.data.languageID : 3,
        languageKey: action.data ? action.data.languageKey : 'ar',
        languageName: action.data ? action.data.languageName : 'العربية',
        languageDirection: action.data ? action.data.languageDirection : 'rtl',
      };
    case types.SET_CURRENCY:
      return {
        ...state,
        currency: action.data ? action.data.currency : 'LYD',
        phoneDialCode: action.data ? action.data.phoneDialCode : '218',
        countryShortCode: action.data ? action.data.countryShortCode : 'LY',
      };
    case types.SET_WEBSITE:
      return {
        ...state,
        websiteId: action.data ? action.data.websiteId : 1,
        subsiteId: action.data ? action.data.subsiteId : 1,
      };
    case types.SET_TAXCLASS:
      return {
        ...state,
        taxClass: action.data ? action.data.taxClassName : 'VAT',
      };
    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  password: (
    credentials: {
      password: string;
      userToken?: string;
      deviceID?: string;
      location?: string;
      latitude?: string;
      longitude?: string;
    },
    websiteId?: number,
    subsiteId?: number,
    onSuccess?: (loginOTP: string) => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.LOGIN_REQUEST,
    });
    try {
      const response = await api.user.password(credentials);
      const { data } = response;
      if (data.response === 'Failure' || data.error === 1) {
        // toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.LOGIN_ERROR,
          data: { errorMessage: data.errorMsg },
        });
      } else {
        if (response.data.result.loginOTP === 'Yes') {
          dispatch({ type: types.REDIRECT_OTP, data: response.data.result });
          if (onSuccess) onSuccess('Yes');
        } else {
          if (
            response.data.result.verified &&
            response.data.result.acceptTerms === 'Yes'
          ) {
            api.setToken(response.data.result.userToken);
            api.setCustomerLanguage(
              response.data.result?.userDefaultLanguage?.languageID || 3
            );
            api.setCustomerLanguageKey(
              response.data.result?.userDefaultLanguage?.languageCode || 'ar'
            );
            api.setWebsiteId(websiteId);
            api.setSubsiteId(subsiteId);
          }
          dispatch({
            type: types.LOGIN_SUCCESS,
            data: { ...response.data.result, websiteId, subsiteId },
          });
          if (onSuccess) onSuccess('No');
        }
      }
    } catch (error) {
      dispatch({
        type: types.LOGIN_ERROR,
        data: { errorMessage: 'Invalid Password!' },
      });
    }
  },

  submitOtp: (
    otp: string,
    userToken?: string,
    passwordExists?: boolean,
    loginOTP?: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.OTP_REQUEST,
    });
    try {
      const response =
        loginOTP === 'Yes'
          ? await api.user.verifyOTPCustomer(otp, userToken)
          : await api.user.submitOtp(otp, userToken);
      const { data } = response;
      if (data.response === 'Failure') {
        // toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.OTP_ERROR,
          data: { otpErrorMessage: data.errorMsg },
        });
      } else {
        if (passwordExists) {
          api.setToken(userToken);
          api.setCustomerLanguage(
            response.data.result?.userDefaultLanguage?.languageID || 3
          );
          api.setCustomerLanguageKey(
            response.data.result?.userDefaultLanguage?.languageCode || 'ar'
          );
        }
        if (loginOTP === 'Yes') {
          dispatch({ type: types.LOGIN_SUCCESS, data: response.data.result });
        } else {
          dispatch({
            type: types.OTP_SUCCESS,
            data: userToken,
            passwordExists,
            userDefaultLanguage: response.data.result?.userDefaultLanguage,
          });
        }

        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.OTP_ERROR,
        data: { otpErrorMessage: 'Invalid Otp!' },
      });
    }
  },

  acceptTermsofUse: (userToken?: string, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({
      type: types.OTP_REQUEST,
    });
    try {
      const response = await api.user.acceptTerms(userToken);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.OTP_ERROR,
          data: { otpErrorMessage: data.errorMsg },
        });
      } else {
        dispatch({
          type: types.TERMS_SUCCESS,
          data: data.result,
        });

        api.setToken(userToken);
        api.setCustomerLanguage(
          response.data.result?.userDefaultLanguage?.languageID || 3
        );
        api.setCustomerLanguageKey(
          response.data.result?.userDefaultLanguage?.languageCode || 'ar'
        );
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.OTP_ERROR,
        data: { otpErrorMessage: 'Invalid Otp!' },
      });
    }
  },

  forgotPassword: (userLogin: string, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({
      type: types.FORGOT_PASSWORD_REQUEST,
    });
    try {
      const response = await api.user.forgotPassword(userLogin);
      const { data } = response;
      if (data.response === 'Failure' || data.error === 1) {
        // toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.FORGOT_PASSWORD_ERROR,
          data: { errorMessage: data.errorMsg },
        });
      } else {
        dispatch({
          type: types.FORGOT_PASSWORD_SUCCESS,
          data: response.data.result,
          userLogin,
        });
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.FORGOT_PASSWORD_ERROR,
        data: { errorMessage: 'Invalid User email or Mobile number' },
      });
    }
  },

  changePassword: (
    otp: string,
    newPassword: string,
    userToken?: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.CHANGE_PASSWORD_REQUEST,
    });
    try {
      const response = await api.user.changePassword(
        otp,
        newPassword,
        userToken
      );
      const { data } = response;
      if (data.response === 'Failure' || data.error === 1) {
        // toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.CHANGE_PASSWORD_ERROR,
          data: { errorMessage: data.errorMsg },
        });
      } else {
        dispatch({
          type: types.CHANGE_PASSWORD_SUCCESS,
          data: response.data.result,
        });
        toastr.success('Success', 'Password changed successfully');
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.CHANGE_PASSWORD_ERROR,
        data: { errorMessage: 'Invalid Password' },
      });
    }
  },

  resendOTP: (
    userLogin: string,
    userToken?: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.RESEND_OTP_REQUEST,
    });
    try {
      const response = await api.user.resendOTP(userLogin, userToken);
      const { data } = response;
      if (data.response === 'Failure') {
        // toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.RESEND_OTP_SUCCESS,
          data: { errorMessage: data.errorMsg },
        });
      } else {
        dispatch({
          type: types.RESEND_OTP_ERROR,
          data: response.data.result,
        });
        toastr.success('Success', 'OTP send successfully');
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.CHANGE_PASSWORD_ERROR,
        data: { errorMessage: 'Invalid Request' },
      });
    }
  },

  fetchProfile: (onSuccess?: () => void) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();
    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    try {
      const response = await api.user.getStudentProfile();
      const { data } = response;
      if (data.response === 'Failure' || data.error === 1) {
        api.setToken(undefined);
      }
      dispatch({ type: types.FETCH_PROFILE_SUCCESS, data });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: types.FETCH_PROFILE_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },

  logout: () => async (dispatch: Dispatch) => {
    api.setToken(undefined);
    api.setCustomerToken(undefined);
    api.setCustomerLanguage(3);
    api.setCustomerLanguageKey('ar');
    api.setWebsiteId(1);
    api.setSubsiteId(1);
    dispatch({ type: types.LOGOUT });
    localStorage?.removeItem("skippedSurvey");
  },

  redirectSave: (to: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.REDIRECT_SAVE, data: to });
  },

  redirectApply: () => (dispatch: Dispatch) =>
    dispatch({ type: types.REDIRECT_APPLY }),

  changeCustomer: (customer?: CustomerCart, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    api.setCustomerToken(customer ? customer.customerToken : undefined);
    dispatch({ type: types.CHANGE_USER, data: customer });
    if (onSuccess) {
      onSuccess();
    }
  },
  switchCustomer: (
    customer?: any,
    customerToken?: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    api.setCustomerToken(customer ? customerToken : undefined);
    dispatch({ type: types.SWiICH_USER, data: { ...customer, customerToken } });
    if (onSuccess) {
      onSuccess();
    }
  },
  changeLanguage: (language?: any, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    api.setCustomerLanguage(language.languageID);
    api.setCustomerLanguageKey(language.languageKey);

    dispatch({
      type: types.CHANGE_LANGUAGE,
      data: {
        languageID: language.languageID,
        languageKey: language.languageKey,
        languageName: language.languageName,
        languageDirection: language.languageDirection,
      },
    });
    if (onSuccess) {
      onSuccess();
    }
  },
  setCurrency: (
    currency?: string,
    countryCode?: string,
    countryShortCode?: string
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.SET_CURRENCY,
      data: {
        currency,
        phoneDialCode: countryCode,
        countryShortCode,
      },
    });
  },

  setWebsite: (websiteId?: number, subsiteId?: number) => async (
    dispatch: Dispatch
  ) => {
    api.setWebsiteId(websiteId);
    api.setSubsiteId(subsiteId);

    dispatch({
      type: types.SET_WEBSITE,
      data: {
        languageID: websiteId,
        languageKey: subsiteId,
      },
    });
  },

  setTaxClass: (taxClassName: string) => async (dispatch: Dispatch) => {
    api.setTaxClassName(taxClassName);

    dispatch({
      type: types.SET_TAXCLASS,
      data: {
        taxClassName,
      },
    });
  },
};
