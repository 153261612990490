import {
  CrudService,
  UserService,
  ProductService,
  CartService,
  OrderService,
  CommonService,
  NotificationService,
  SalesPersonService,
  BundleService,
  SearchService,
} from './services';
import ApiServiceDataStore from './services/data';
import FulfillerService from './services/fulfiller.service';

export default class EdsApiClient {
  public crud: CrudService;
  public user: UserService;
  public product: ProductService;
  public cart: CartService;
  public order: OrderService;
  public common: CommonService;
  public notification: NotificationService;
  public salesperson: SalesPersonService;
  public bundleOffer: BundleService;
  public search: SearchService;
  public fulfiller: FulfillerService
  private store: ApiServiceDataStore = {
    apiDomain: '',
    authToken: undefined,
    customerToken: undefined,
    languageId: undefined,
    languageKey: undefined,
    websiteId: undefined,
    subsiteId: undefined,
  };

  constructor(apiDomain: string) {
    this.store.apiDomain = apiDomain;
    this.crud = new CrudService(this.store);
    this.user = new UserService(this.store);
    this.product = new ProductService(this.store);
    this.cart = new CartService(this.store);
    this.order = new OrderService(this.store);
    this.common = new CommonService(this.store);
    this.notification = new NotificationService(this.store);
    this.salesperson = new SalesPersonService(this.store);
    this.bundleOffer = new BundleService(this.store);
    this.search = new SearchService(this.store);
    this.fulfiller = new FulfillerService(this.store);
  }

  public setToken(token?: string) {
    this.store.authToken = token;
  }
  public setCustomerToken(token?: string) {
    this.store.customerToken = token;
  }
  public setCustomerLanguage(languageId?: number) {
    this.store.languageId = languageId || 3;
  }
  public setCustomerLanguageKey(languageKey?: string) {
    this.store.languageKey = languageKey || 'ar';
  }

  public setWebsiteId(websiteId?: number) {
    this.store.websiteId = websiteId || 1;
  }
  public setSubsiteId(subsiteId?: number) {
    this.store.subsiteId = subsiteId || 1;
  }
  public setTaxClassName(taxClassName?: string) {
    this.store.taxClass = taxClassName || 'VAT';
  }
}
