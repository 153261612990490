import * as http from './http';
import ApiService from './service';

export default class SearchService extends ApiService {
  public async searchClick(searchData?: any) {
    const url = `${this.apiDomain}/search/`;
    const data = new FormData();
    data.append('userToken', this.token ? this.token : '');
    data.append('website_id', this.websiteId ? this.websiteId.toString() : '1');
    data.append('subsite_id', this.subsiteId ? this.subsiteId.toString() : '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    data.append('searchLogID', searchData.searchLogID);
    data.append('eventType', searchData.eventType);
    data.append('deviceType', 'Web');

    return http.postAsFormData(url, data);
  }
}
